$sm-screen-size: 576px;

.chat {
    .chat-window {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
        border-radius: 10px;
        width: 340px;
        height: 600px;
        transition: all 0.25s ease-in;
        transform: scaleX(0) scaleY(0);
        transform-origin: 280px 570px;
        position: fixed;
        bottom: 40px;
        right: 60px;
        z-index: 10001;
        background: #fff;

        .chat-communication {
            height: 600px;
            display: flex;
            flex-direction: column;
            opacity: 1;
            transition: opacity 0.3s ease;

            @media (max-width: $sm-screen-size) {
                height: 100%;
            }

            &.hidden {
                opacity: 0;
            }
        }

        @media (max-width: $sm-screen-size) {
            width: 100%;
            height: 100%;
            bottom: 0;
            right: 0;
            border-radius: 0;
            font-size: 1rem;
        }

        // CHAT HEADER
        .chat-header {
            .header__content {
                display: flex;
                color: #fff;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                padding: 1rem;
                border-radius: 10px 10px 0 0;
                background-color: #70b635;

                @media (max-width: $sm-screen-size) {
                    border-radius: 0;
                }
            }

            .header__image {
                width: 40px;
                height: 40px;
            }

            .header__name {
                margin: 0;
                display: inline;
                font-weight: bolder;
                flex-grow: 1;
                padding-left: 0.5rem;
            }

            .header__deactivate {
                padding: 0;
                background: transparent;
                color: #fff;
                margin-bottom: 7px;
                cursor: pointer;

                & > img {
                    width: 22px;
                    height: 22px;
                }
            }

            .header__resolve {
                margin-bottom: 7px;
                margin-left: 7px;
                cursor: pointer;

                & > img {
                    width: 24px;
                    height: 24px;
                }
            }

            .status-bar {
                padding: 0.5rem 1rem 1rem;
                position: relative;
                background: rgba(112, 182, 53, 0.8);

                &:after {
                    z-index: 0;
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: -8px;
                    left: 0;
                    border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
                    border-image-slice: 0 0 100%;
                    border-image-width: 0 0 16px;
                    border-image-repeat: stretch;
                    border-width: 0 0 15px;
                    border-bottom-style: solid;
                    border-color: initial;
                    border-top-style: initial;
                    border-left-style: initial;
                    border-right-style: initial;
                }

                &__info {
                    color: #fff;
                    margin: 0;
                    margin-top: 0.25rem;
                    font-size: 14px;
                }
            }
        }

        // MESSAGE BAR
        .chat-message-bar {
            position: relative;
            width: 100%;
            padding: 1rem;
            flex-grow: 1;
            flex-shrink: 0;

            .message-bar-wrapper {
                display: flex;
                align-items: center;
            }

            .message-bar__text-input {
                width: 100%;
                font-size: 14px;
                border: unset !important;
                outline: unset !important;
                box-shadow: unset !important;

                @media (max-width: $sm-screen-size) {
                    width: unset;
                    flex-grow: 1;
                    font-size: 16px;
                }

                &::-webkit-resizer {
                    display: none;
                }
            }
        }

        .message-bar__divider {
            margin-bottom: 10px;
            height: 1px;
            width: calc(100% - 20px);
            background-color: rgb(175 175 175 / 31%);
        }

        .send-message-button {
            align-content: center;
            justify-content: center;
            -webkit-appearance: none;
            border: unset;
            border-radius: 100%;
            background-color: #70b635;
            position: absolute;
            right: -25px;
            bottom: 8px;
            width: 60px;
            height: 60px;
            transition: all 0.25s ease;
            transform: translateY(-10px);

            @media (max-width: $sm-screen-size) {
                position: relative;
                right: 0;
                bottom: 0;
                transform: translateY(0);
            }

            &:hover {
                transform: translateY(-10px) scale(1.1);
            }

            &:active {
                outline: 0 !important;
                transform: translateY(-10px) scale(1);
            }

            .send-icon-wrapper {
                margin-left: 4px;
                margin-bottom: 2px;
            }

            &.disabled {
                background-color: #ffffff;
                box-shadow: 1px 1px 10px 3px #00000024;

                .send-icon-wrapper {
                    margin: 0;
                }
            }
        }

        // CHAT BODY
        .chat-body {
            flex-grow: 1;
            height: 100%;
            padding: 0.5rem 1rem 0;
            overflow-y: scroll;
            position: relative;
            scroll-behavior: smooth;

            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }

            .messages {
                display: flex;
                flex-direction: column;
                height: auto;
                min-height: 100%;
                justify-content: end;
                gap: 5px;

                .chat-message {
                    display: flex;
                    word-wrap: break-word;

                    .timestamp {
                        font-size: 10px;
                        color: rgba(0, 0, 0, 0.29);
                    }

                    .content {
                        border-radius: 5px;
                        margin-top: 2px;
                        font-size: 14px;
                        padding: 10px;
                        max-width: 80%;

                        @keyframes spawn-message {
                            from {
                                opacity: 0;
                            }
                            to {
                                opacity: 1;
                            }
                        }

                        animation: spawn-message 0.3s;
                    }

                    &.typing {
                        .content {
                            position: absolute;
                            opacity: 0;
                            transition: all 0.3s ease;
                            font-size: 10px;
                            background-color: #e8e8e8;
                            color: rgba(66, 66, 66, 0.49);

                            &.active {
                                opacity: 1;
                            }
                        }
                    }

                    &_sender {
                        flex-direction: column;
                        align-items: end;
                        justify-content: end;
                        .content {
                            background-color: #70b635;
                            color: white;
                        }
                    }

                    &_server {
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;
                        .content {
                            background-color: #d5d5d5;
                            color: #424242;
                        }
                    }

                    &_system {
                        width: 100%;
                        margin-top: 0.5rem;
                        .content {
                            @include system-message;
                        }

                        a {
                            color: #70b635;
                        }
                    }

                    &_button {
                        flex-direction: column;
                        margin-top: 0.5rem;
                        .content {
                            @include system-message;

                            .message-button__wrapper {
                                position: relative;
                                display: flex;
                                justify-content: center;
                            }
                        }

                        .btn-redirect {
                            margin-top: 0.5rem;
                            -webkit-appearance: none;
                            border-radius: 5px;
                            padding: 5px 10px 5px 10px;
                            border: unset;
                            background-color: #70b635;
                            color: white;
                            transition: all 0.25s ease;

                            &:hover {
                                transform: scale(1.05);
                            }

                            &:active {
                                outline: 0 !important;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
    }

    .chat-head {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background-color: #70b635;
        border-radius: 100%;
        position: fixed;
        bottom: 40px;
        right: 40px;
        transition: all 0.2s ease;
        z-index: 10000;
    }

    &.active {
        .chat-window {
            transition: all 0.35s cubic-bezier(0.42, 0, 0.09, 1.28);
            transform: scaleX(1) scaleY(1);
        }

        .chat-head {
            transform: scaleX(0) scaleY(0);
        }
    }

    & .out-of-service-info {
        font-size: 0.9rem;
    }

    & .form-input_auth {
        @include form-control;
        width: 125px;
        margin: 0 auto;
    }
}

.chat-message_gdpr {
    font-style: italic;
    font-size: 13px;
}

.chat-popover {
    background-color: #fff;
    color: #555;
    border-radius: 8px;
    padding: 10px;
    max-width: 300px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;

    &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 8px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        transform: translateX(-50%);
    }
}

@keyframes help-suggestion-animation {
    0% {
        transform: translate(0, 15px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes wave-hand-animation {
    0% {
        transform: translate3d(0, 0, 0) rotate(0);
        font-size: 18px;
    }
    16% {
        transform: translate3d(18px, -35px, 0) rotate(70deg);
        font-size: 30px;
    }
    32% {
        transform: translate3d(-7px, -35px, 0) rotate(0);
        font-size: 30px;
    }
    48% {
        transform: translate3d(18px, -35px, 0) rotate(70deg);
        font-size: 30px;
    }
    64% {
        transform: translate3d(-7px, -35px, 0) rotate(0);
        font-size: 30px;
    }
    80% {
        transform: translate3d(18px, -35px, 0) rotate(70deg);
        font-size: 30px;
    }
    100% {
        transform: translate3d(0, 0, 0) rotate(0);
        font-size: 18px;
    }
}

.help-suggestion {
    animation: help-suggestion-animation 0.6s ease forwards;
    align-items: center;
    bottom: 120px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 40px;
    padding: 16px;
    transition: all 0.2s ease;
    width: 420px;
    z-index: 10000;
    background-color: white;
    border-radius: 10px;

    &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        right: 22px;
        transform: rotate(45deg);
        border-radius: 3px;
        width: 20px;
        height: 20px;
        background-color: white;
        //box-shadow: 0 1rem 3rem rgba(0,0,0,.25)!important;
        z-index: 10000;
    }

    .help-suggestion-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .help-suggestion-text {
            color: #414141;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
            width: 100%;

            .hand {
                animation: wave-hand-animation 1.2s ease-in-out;
                animation-delay: 0.5s;
                top: 13px;
                transition: transform 0.2s ease;
                margin-left: 0.5rem;
                position: absolute;
                left: 71px;
                font-size: 18px;
            }
        }

        .help-suggestion-button {
            background: #6eb334;
            color: white;
            border-radius: 0.5rem;

            & img {
                width: 20px;
                height: 20px;
                margin-left: 8px;
                color: white;
            }
        }

        .help-suggestion-close-button {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 23px;
            height: 23px;
            opacity: 0.8;
        }
    }
}

@media (max-width: 768px) {
    .help-suggestion {
        width: 320px;
    }
}

.message-suggestion {
    animation: help-suggestion-animation 0.6s ease forwards;
    align-items: center;
    bottom: 120px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 40px;
    padding: 16px;
    transition: all 0.2s ease;
    width: 330px;
    z-index: 10001;
    background-color: white;
    border-radius: 10px;

    &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        right: 22px;
        transform: rotate(45deg);
        border-radius: 3px;
        width: 20px;
        height: 20px;
        background-color: white;
        //box-shadow: 0 1rem 3rem rgba(0,0,0,.25)!important;
        z-index: 10000;
    }

    .message-suggestion-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .message-suggestion-text {
            color: #414141;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
            width: 100%;

            .hand {
                animation: wave-hand-animation 1.2s ease-in-out;
                animation-delay: 0.5s;
                top: 13px;
                transition: transform 0.2s ease;
                margin-left: 0.5rem;
                position: absolute;
                left: 71px;
                font-size: 18px;
            }
        }

        .message-suggestion-button {
            background: #6eb334;
            color: white;
            border-radius: 0.5rem;

            & img {
                width: 20px;
                height: 20px;
                margin-left: 8px;
                color: white;
            }
        }

        .message-suggestion-close-button {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 23px;
            height: 23px;
            opacity: 0.8;
        }
    }
}

.chat-notification {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
    85%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.chat-notification-static {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
}

.form__content {
    & .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & .feedback-button-wrapper {
            display: flex;
            justify-content: center;
            gap: 15px;

            & .feedback-button {
                border-radius: 5px;
                border: none;
                background-color: #e8e8e8;
                padding: 15px;
                transition: 0.2s transform ease;

                &:hover {
                    transform: scale(1.05);
                }

                &__icon {
                    font-size: 20px;
                    width: 100%;
                }
            }
        }
    }
}
