.buttons-bar {
    display: flex;
    justify-content: space-around;

    .form-button {
        color: white;
        transform: scale(1.1);
        transition: transform 0.2s ease;

        &.submit {
            background: #6fb533;
        }

        &.cancel {
            background: #b53333;
        }

        &:hover {
            transform: scale(1.15);
        }

        &:focus {
            outline: unset !important;
        }
    }
}

.auth-form-input {
    @include form-control;
    margin-bottom: 1rem;
}
