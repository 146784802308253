.chat-form {
    position: absolute;
    opacity: 0;
    z-index: 3;
    background-color: #fff;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: 10px;
    left: 0;
    top: 0;
    transition: opacity 0.3s ease;

    &.form-open {
        pointer-events: auto;
        opacity: 1;
    }
}

.form__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 70%;
}

.close-form-bar {
    display: flex;
    justify-content: end;
    padding: 1rem;

    .close-form-icon {
        cursor: pointer;
    }
}
