.image-wrapper {
    display: flex;
    justify-content: center;

    .auth-form-image {
        margin-bottom: 3rem;
        width: 100px;
        height: 100px;
    }

    .out-of-service-image {
        padding: 0 23% 13%;

        &.success {
            .call {
                display: none;
                transition: filter 0.2s ease;
            }

            .ok {
                display: block;
            }
        }

        .call {
            display: block;
        }

        .ok {
            display: none;
        }

        &.error {
            .call {
                filter: hue-rotate(-60deg);
            }
        }
    }
}

.pincode-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pincode-input {
        width: 80px;
        text-align: center;
    }
}

.error {
    color: #c75353;
}

.auth-form-input {
    margin-bottom: 1rem;
    @include form-control;
}
